.toggeleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;

  .toggeleButton:focus {
    outline: none;
  }

  .toggeleButtonLine {
    width: 24px;
    height: 2px;
    background: #000000a6;
  }
}

@media (max-width: 768px) {
  .toggeleButton {
    height: 20px;
    width: 20px;
    padding: 0;

    .toggeleButtonLine {
      height: 1px;
    }
  }
}
.memberShip_container {
  background: #FFFFFF;
  font-family: HM Sans Regular;
  padding: 0 24px;

  .information {
    display: flex;
    flex-direction: column;
    padding: 25px 0px 10px 0px;
    ;
    align-items: flex-start;
  }
}
.banner-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-container div {
  display: flex;
  align-items: center;
  justify-self: center;
}

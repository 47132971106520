.textFieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;

  .textarea {
    border: 0px;
    font-family: HM Sans Regular;
    padding: 8px;
    font-size: 13px;
    color: #222222;
    width: 80%;
    text-align: center;
    background: #FAF9F8;
    resize: none;
  }

  .textarea:focus {
    outline: none;
  }
}
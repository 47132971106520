.checkBox_container {
    background: #FFFFFF;
    font-family: HM Sans Regular;
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: column;

    .error {
        color: #D6001C;
        font-size: 11px;
        font-weight: bold;
        font-family: HM Sans;
        padding-left: 34px;
        padding-top: 4px;
    }

    .checkbox_middle {
        border-bottom: 2px solid #222222;
        height: 97px;
        margin: 0px 24px 13px 24px;

        .checkBoxGroup {
            display: flex;
            align-items: center;
            padding: 9px 0px;
        }

        .checkBoxGroup input {
            width: 20px;
            height: 20px;
        }

        .checkBoxGroup input[type="checkbox"]:checked {
            background: black;
            color: white;
        }

        .checkBoxGroup label {
            padding-left: 16px;
        }
    }


}
.search-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20px;

  input {
    border: 1px solid #d0d0d0;
    font-family: HM Sans Regular;
    font-size: 16px;
    padding-left: 15px;
  }

  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }

  input:focus {
    outline: none;
  }
}
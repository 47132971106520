.order-return-container {
  width: 100%;
  box-sizing: border-box;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rental-items {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-top: 10px;
    box-sizing: border-box;
  }
  .item-container {
    width: 100%;
    margin-top: 10px;
  }
  .btn-container {
    width: 100%;
    margin-top: 25px;
  }
  .error-container {
    width: 100%;
    margin-top: 10px;
  }
  .fast-return {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-top: 10px;
    box-sizing: border-box;
    background-color: #fff;
    align-items: center;
    margin-bottom: 25px;
    text-align: center;
  }
  .fast-return .btn-container {
    margin-bottom: 10px;
  }
}

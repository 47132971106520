.rental-list-container {
  width: 100%;
  background-color: #faf9f8;
  padding-bottom: 25px;
  .top_title {
    margin: 25px 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .search-field {
    width: 90%;
    margin: 0 5%;
  }
  .outForRent-list-container,
  .inspection-list-container {
    width: 92%;
    margin: 20px 4% 0 5%;
  }
  .list-title {
    width: 100%;
    margin-bottom: 10px;
  }
  .no-orders {
    text-align: center;
    font-size: 13px;
    margin-top: 20px;
  }
}

.member_id_container {
  background: #faf9f8;
  font-family: HM Sans Regular;

  .search_inputBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .search_top_row {
      background-color: #faf9f8;
      width: 100%;
    }
  }

  .member_information {
    display: flex;
    flex-direction: column;
    padding: 48px 0 26px 0;
    align-items: center;
    letter-spacing: 1.08px;
  }
}
.primary {
  background-color: #000;
  color: white;
  font-size: 16px;
  font-family: HM Sans Regular;
  border: none;
}

.secondary {
  background-color: #fff;
  color: black;
  font-size: 16px;
  font-family: HM Sans Regular;
  border: 1px solid black;
}

.underline {
  background-color: transparent;
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: HM Sans Regular;
  text-decoration: underline;
  border: none;
}
.disable {
  background-color: #d0d0d0;
  color: #707070;
  font-size: 16px;
  font-family: HM Sans Regular;
  border: none;
}

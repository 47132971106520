.sideDrawer {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #222222b5;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  box-sizing: border-box;

  .nav_container {
    height: 100%;
    width: 88%;
    background: white;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin-top: 0;

      li:first-child {
        text-decoration: underline;
        font-size: 13px;
        display: flex;
        align-items: center;
        font-weight: bold;

        a {
          padding-left: 13px;
        }
      }

      li:focus,
      li:hover {
        background-color: #f0ddd7;
      }

      li {
        margin: 0.2rem 0;
        padding: 24px;
        font-size: 20px;
        font-weight: bold;

        a {
          color: #222222;
          text-decoration: none;
        }
      }
    }
  }
  .close {
    padding: 12px;
  }
  .link {
    font-size: 20px;
  }
  .linkTo {
    display: flex;
    justify-content: space-between;
  }
}

.open {
  transform: translateX(0);
  display: flex;
  align-items: center;
}

.selectBox {
  width: 100%;

  .error {
    color: #D6001C;
    font-size: 13px;
    font-weight: bold;
    font-family: HM Sans;
    padding-top: 4px;
  }
}

.selectboxContainer {
  display: flex;
  background-color: #fff;
  border: 1px solid rgb(170, 170, 170);
  justify-content: center;

  .selectedItem {
    font-size: 16px;
    width: 72%;
    font-family: HM Sans Arabic;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: #707070;
  }

  .selected {
    color: #000;
  }

  .selectboxArrow {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
    height: 48px;

    .selectboxArrowUp {
      border: solid #606060;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .selectboxArrowDown {
      border: solid #222222;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}

.selectboxItems {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  position: absolute;
  background-color: #fff;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;

  .selectboxItems div {
    padding: 12px;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
  }

  .selectboxItems div:first-child {
    margin-top: 0px;
  }

  .selectboxItems div:hover {
    background-color: #f4eddd;
  }

  .seletedOption {
    padding: 0 24px;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    background-color: #f4eddd;
  }

  .option {
    padding: 0 24px;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    background-color: #fff;
  }
}

@media (max-width: 768px) {
  .selectboxItems {
    // width: 85%;
  }
}
.Logo{
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px;
    content: "";
    display: block;
    height: 35%;
    width: auto;

}

@media (max-width:768px) {
    .Logo {
        height: 24px ;
        margin: 5px;
    }
}
.item_summary_container {

  .content {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 24px 0px;

    .item_summary_left {
      .item_summary_img {
        height: 143px;
        width: 95px;
        margin: 0px 24px 0px 0px;
      }
    }

    .item_summary_right {
      overflow-wrap: anywhere;
      font-weight: 500;
      margin-top: -20px;

      .item_summary_padding_top {
        padding-top: 5px;
      }

      .item_summary_article_info {
        padding: 2px 0;

        .item_summary_table tr td {
          padding: 2px 35px 2px 0px;
        }
      }
    }
  }

}
.popup_container {
  height: 226px;
  width: 335px;
  right: 16px;
  background-color: #FFFFFF;
  top: 60px;
  position: fixed;

  .popup_top {
    display: flex;
    justify-content: flex-end;
    font-family: HM Sans Regular;
    padding: 28px 34px 0px 0;
    font-size: 13px;
    font-weight: bold;

    .popup_amount {
      font-size: 10px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    font-size: 13px;

    .popup_left {
      .popup_img {
        height: 120px;
        width: 80px;
        margin: 16px 16px 7px 24px;
      }
    }

    .popup_right {
      overflow-wrap: anywhere;

      .popup_padding_top {
        padding-top: 2px;
      }

      .popup_article_info {
        padding: 2px 0;

        .popup_table tr td {
          padding: 2px 35px 2px 0px;
        }
      }
    }
  }
}

.basket_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .content {
    display: flex;
    align-items: flex-start;
    font-size: 13px;

    .basket_left {
      .basket_img {
        height: 157px;
        width: 104px;
        margin: 0px 16px 24px 10px;
      }
    }

    .basket_right {
      overflow-wrap: anywhere;
      font-weight: 500;
      font-size: 16px;

      .basket_padding_top {
        padding-top: 2px;
      }

      .basket_article_info {
        padding: 2px 0;

        .basket_table tr td {
          padding: 2px 35px 2px 0px;
        }
      }
    }
  }

}
.item-container {
  width: 100%;
  display: flex;
  .left {
    width: 35%;
  }
  .right {
    width: 65%;
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
    .row:first-child {
      margin-top: 0px;
    }
    .inside-row {
      width: 100%;
      display: flex;
      .column {
        width: 40%;
      }
    }
  }
  .img-container {
    width: 95px;
    height: 100%;
    border: 1px solid black;
  }
}

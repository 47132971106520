 .no-show-container {

     .no-show-content {
         display: flex;
         flex-direction: column;
         align-items: center;
         padding: 32px 48px 38px 48px;
     }

     .no-show-input {
         text-align: start;
         padding: 0 25px 0 25px;

         .no-show-input-content {
             display: flex;
             justify-content:space-evenly;
             align-items: center;
             padding: 7px 0;

         }

         .line {
             border-bottom: 1px solid #BCBCBC;
             margin: 48px 0;
         }

         .no-show-amount {
             display: flex;
             flex-direction: column;
             align-items: center;
             padding-bottom: 32px;
         }

         .tableDiv {
             width: 100%;
             margin: 0 auto;

             table {
                 width: 100%;
                 border-spacing: 0;
             }

             table tr td {
                 height: 51px;
                 padding-left: 50px;
                 font-size: 16px;
             }

             table tr td:last-child {}

             table tr th:last-child {
                 text-align: center;
             }

             table tr th {
                 height: 40px;
                 font-size: 16px;
                 font-weight: 400;
                 font-family: HM Sans Regular;
             }

             tr:nth-of-type(even) {
                 background: #F0EBE0;
             }
         }
     }
 }
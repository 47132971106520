.modalContainer {
    height: 100%;
    position: fixed;
    display: flex;
    width: 100%;
    top: 0;
    flex-direction: column;
    z-index: 2;
    justify-content: center;
    align-items: center;

    .content {
        background-color: #FAF9F8;
        padding: 10px 10px 48px 10px;
        width: 295px;
        height: 315px;

        .modalHeader {
            font-family: HM Sans;
            font-size: 16px;
            width: 212px;
            text-align: center;
            margin: auto;

            .modalInfo {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 64px 0 32px 0;

                .font-bold {
                    font-weight: bold;
                    margin: 5px 0;
                }
            }
        }

        .buttons_row {
            .btnContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

    }

}

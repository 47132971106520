.header_container {
  padding: 35px 25px 16px 25px;
  background-color: #faf9f8;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  margin-top: -75px;
  z-index: 1;
  box-sizing: border-box;

  .arrow_left {
    padding-right: 34px;
    display: flex;
    align-content: center;  }

  .page_name {
    font-size: 20px;
    font-family: HM Sans Regular;
    font-weight: bold;
    flex: 8;
    display: flex;
  }
  .dummy {
    flex: 1;
  }
}

.rental_bag_container {
  background: #faf9f8;

  .rental_bag_top_row {
    display: flex;
    padding: 24px;
  }

  .renta_bag_content {
    padding: 24px;

    .info {
      padding-bottom: 24px;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 16px 30px 32px 33px;
  }

  .checkbox_buttom {
    background-color: #FFFFFF;
    margin: 0 24px 50px 24px;
  }
}

.empty_bag {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 20px 10px 20px;
  background-color: #FFFFFF;
}
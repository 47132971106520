.header {
  width: 100%;
  height: 72px;
  position: sticky;
  top: 0;
  background: #faf9f8;
  box-sizing: border-box;
  z-index: 1;
  padding: 0 25px;

  .headerNav {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;

    .sideDrawerBtn {
      display: flex;
    }

    a {
      display: flex;
      flex: 8;
      align-items: center;
      justify-content: center;
    }

    .logo {
      height: 100px;
      display: flex;
      align-items: center;
      color: #e11f26;
      font-weight: bold;
      font-size: 16px;

      .rentalsText {
        color: red;
        margin-left: 5px;
        font-family: HM Sans Regular;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .spacer {
      display: flex;
    }

    .menus {
      display: flex;
      justify-content: center;

      .menus a {
        text-decoration: none;
        color: black;
      }

      .menus ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
      }

      .menus ul li {
        font-family: HM Sans Regular;
      }

      .menus ul li:first-child {
        margin-right: 20px;
      }
    }

    .userProfile {
      font-family: HM Sans Regular;

      .userIcon {
        height: 24px;
        width: 24px;
        padding-right: 20px;
      }

      .shoppingBag {
        display: flex;

        .amount {
          display: flex;
          position: absolute;
          padding: 6px 0px 0px 6px;
          font-size: 10px;
          height: 12 px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.filler {
  flex: 2;
}

@media (max-width: 768px) {
  .headerNav {

    .menus,
    .filler {
      display: none;
    }

    .userProfile {
      .userIcon {
        padding-right: 6px !important;
      }

      .bagIcon {
        padding-left: 6px;
      }

      .amount {
        padding: 6px 0px 0px 6px;
      }
    }
  }
}
.order-details-container {
  width: 100%;
  box-sizing: border-box;
  .banner {
    width: 100%;
    padding: 15px 25px;
    box-sizing: border-box;
  }
  .rental-summary {
    width: 100%;
    padding: 25px;
    padding-top: 10px;
    background-color: #fff;
    box-sizing: border-box;
  }
  .rental-items {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-top: 10px;
    box-sizing: border-box;
  }
  .item-container {
    width: 100%;
    margin-top: 10px;
  }
  .btn-container {
    width: 100%;
    margin-top: 20px;
  }
  .btn-container a {
    width: 100%;
  }
}

.info_container {
    width: 100%;
    padding: 49px 0;
    background: white;
    margin-top: 24px;

  .title {
    width: 100%;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }

  .button-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .button-row a {
    width: 100%;
  }
}
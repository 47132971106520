.card_container {
  .card_top {
    display: flex;
    font-family: HM Sans Regular;

    .card_left {
      .card_img {
        height: 242px;
        width: 161px;
        margin-bottom: 16px;
      }
    }

    .card_right {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      overflow-wrap: anywhere;
      width: 100%;
      justify-content: space-around;
      height: 245px;

      .productName {
        font-size: 13px;
      }

      .info {
        font-size: 16px;
        padding-top: 4px;

      }

      .red {
        font-size: 13px;
        padding-top: 2px;
        color: #D6001C
      }

      .card_buttom {
        padding-top: 40px;
      }
    }
  }
}
.no_show_info_container {
  margin: 49px auto;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .tell_us {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 71px;
  }
}
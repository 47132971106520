.summary_container_rental {
  width: 100%;
  background-color: #fff;
  .title {
    width: 100%;
  }
  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .button-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .button-row a {
    width: 100%;
  }
}

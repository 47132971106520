.add-rental_container {
  background: #faf9f8;

  .add_rental_title {
    display: flex;
    justify-content: center;
    padding: 24px 0 48px 0;
  }

  .member_row {
    background-color: #E4E4E4;
    font-family: HM Sans Regular;
    font-weight: bold;
  }

  .add_rental_top_row {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .search_top_row {
      background-color: #faf9f8;
      width: 100%;
    }
  }

  .error_msg {
    color: #E02020;
    font-weight: bold;
    font-size: 16px;
    padding: 45px;
    display: flex;
    justify-content: center;
    font-family: "HM Sans Regular";
  }

  .card_containers {
    padding: 2px 16px;
  }
}

.maxHeight {
  overflow: hidden;
  height: 90vh;
}

.searchBarContent {
  .tabsStyle {
    display: flex;

    .tabStyle {
      margin: 0px 24px 4px 0;
      font-family: HM Sans Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }

  .searchBarStyle {}
}
.emailContainer {
  margin: 16px;
  border: 1px solid #d0d0d0;
  width: 90%;
  background: white;

  .contaniar {
    display: flex;
    flex-direction: row;
  }

  .error {
    border: 1px solid #D6001C;
  }

  .correct {
    border: 1px solid #00853D;
  }

  .icon {
    width: 24px;
    height: 24px;
    padding: 12px;


  }

  .input_text {
    width: 95%;
    display: flex;

    input:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }

    .noneDisplay {
      display: none;
    }

    .blockDisplay {
      display: block;
    }

  }

  .inputBox {
    border: 0px;
    font-family: HM Sans Regular;
    font-size: 16px;
    color: #222222;
    width: 90%;
    height: 28px;
    margin: 10px 0px;
    border-left: 1px solid #2222228f;
    padding-left: 10px;
  }

  .inputBox:focus {
    outline: none;
  }


}
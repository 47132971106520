.textFieldContent {
  display: flex;
  align-items: center;

  .inputBox {
    border: 1px solid #d0d0d0;
    font-family: HM Sans Regular;
    font-size: 13px;
    color: #222222;
    font-weight: bold;
  }

  .inputBox:focus {
    outline: none;
  }
}
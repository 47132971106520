.errorScreen_container_rental {
  width: 100%;
  background-color: #fff;
  .title {
    width: 100%;
  }

  .row {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }
  .paddingTop {
    padding-top: 50px;
  }
  .button-row {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    margin-top: 20px;
  }
  .button-row a {
    width: 100%;
  }
}

.top_row_container {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 24px;
  background-color: #E4E4E4;
  font-family: HM Sans Regular;

  .top_row_left {
    display: flex;
    width: 70%;
    align-items: center;

    img {
      padding-right: 5px;
    }
  }

  .top_row_right {
    display: flex;
    color: transparent;
    margin: 4px 0;
    font-family: HM Sans;
  }
}


@media (max-width: 768px) {
  .top_row_container {
    margin: 0;
    height: 48px;
  }
}
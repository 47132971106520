.row_container {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row_left {
  display: flex;
  justify-content: flex-start;
}

.row_middel {
  margin: 0 34px 0 16px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 80%;

  .row_status {
    font-size: 16px;
    font-family: HM Sans Regular;
    overflow-wrap: anywhere;
    padding-top: 10px;
    color: #222222;
  }

  .row_articleId,
  .row_product_name {
    font-family: HM Sans Regular;
    font-size: 16px;
    padding-top: 5px;
    height: 20px;
  }
  .row_articleId {
    font-weight: bold;
  }
}

.row_right {
  display: flex;
  align-items: center;
  width: 12%;
}

.member_container {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px;
  .title {
    width: 100%;
  }
  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}
